.type2 {
  width: 13rem;
  height: 100%;
  display: block;
  .btn {
    width: 100%;
    cursor: pointer;
    display: block;
    // background: #4460bc;
    background: #6982bb;
    height: 48px;
    line-height: 48px;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    text-align: center;
    // border-radius: 50px;
    padding: 0 1rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 24px;
    &:hover{
      color: rgba(255, 255, 255, 0.7)
    }
    .pc {
      font-weight: 900;
    }

    .mobile {
      display: none;
    }
  }
}

@media screen and (max-width: 600px) {
  .type2 {
    width: auto;
    transform: unset;

    .btn {
      height: 40px;
      line-height: 40px;

      .pc {
        display: none;
      }

      .mobile {
        display: inline;
        font-weight: 900;
      }
    }
  }
}
